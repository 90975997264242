<script>
import Layout from "@/router/layouts/template-pemohon";
import appConfig from "@/app.config";

import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
// import moment from 'moment';
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Pengajuan Permohonan",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
    },
    data() {
        return {
            title: "Pengajuan Permohonan",
            items: [
                {
                    text: "Dashboard",
                    href: "/pemohon/dashboard",
                },
                {
                    text: "Pengajuan Permohonan",
                    active: true,
                },
            ],
            // Session
            session: JSON.parse(localStorage.session),
            perizinan_id: this.$route.query.perizinan_id,
            perizinan_sub_id: this.$route.query.perizinan_sub_id,
            kode_izin: this.$route.query.kode_izin,
            id_izin: this.$route.query.id_izin,
            // variabel kebutuhan form
            perizinan_sub_form: [],
            currentTabIndex: 0,
            html_form: null,
            konfig_dokumen: [],
            save_as: "draft",
            setuju_permohonan: false,
            // Data Umum
            nomor_surat: null,
            tgl_surat: null,
            perihal: null,
            permohonan_id: null,
            permohonan_dokumen: [],
            list_document: [],
            wizard_now: "data-umum",
            wizard_next: "",
            width_garis: 2,
            act_wizard: "",
            page_preview: "",
            kelengkapan_data_teknis: false,
            message_kelengkapan_data_teknis: [],
            kelengkapan_dokumen_persyaratan: false,
            message_kelengkapan_dokumen_persyaratan: [],
            content_before_loading_trig: "",
            data_disclaimer: [],
        };
    },
    mounted() {
        let self = this;
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src", process.env.VUE_APP_BACKEND_URL + "js/permohonan/" + self.perizinan_sub_id + "/add.js");
        document.head.appendChild(recaptchaScript);
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/load-add", {
                params: {
                    perizinan_id: this.perizinan_id,
                    perizinan_sub_id: this.perizinan_sub_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                self.html_form = response.data;
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.e);
            });
        // Get konfig Dokumen
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/load-dokumen-konfig", {
                params: {
                    perizinan_id: this.perizinan_id,
                    perizinan_sub_id: this.perizinan_sub_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    var raw_document = response_data_fix.referensi;
                    var list_document = [];
                    var permohonan_dokumen = [];
                    $.each(raw_document, function (index, val) {
                        var konfig = {
                            mdpk_id: val.mdpk_id,
                            mdp_id: val.dokumen_persyaratan.mdp_id,
                            mdp_nama: val.dokumen_persyaratan.mdp_nama,
                            mdp_is_mandatori: val.dokumen_persyaratan.mdp_is_mandatori,
                            mdp_is_isian_custom: val.dokumen_persyaratan.mdp_is_isian_custom,
                            mdp_label_isian_custom: val.dokumen_persyaratan.mdp_label_isian_custom,
                            mdp_is_tanggal_awal: val.dokumen_persyaratan.mdp_is_tanggal_awal,
                            mdp_is_tanggal_berakhir: val.dokumen_persyaratan.mdp_is_tanggal_berakhir,
                        };
                        // Untuk Listing Form Nya
                        list_document.push(konfig);
                        // Untuk Dikirim Ke Backend
                        permohonan_dokumen.push({
                            pd_id: null,
                            pd_p_id: null,
                            pd_mdp_id: val.dokumen_persyaratan.mdp_id,
                            pd_mdp_tanggal_awal: null,
                            pd_mdp_tanggal_akhir: null,
                            pd_mdp_label_isian_custom: null,
                            pd_mdp_isian_custom: null,
                            pd_path: null,
                            file: null,
                        });
                    });
                    this.permohonan_dokumen = permohonan_dokumen;
                    this.list_document = list_document;
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
            });
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/get-perizinan-sub", {
                params: {
                    perizinan_id: this.perizinan_id,
                    perizinan_sub_id: this.perizinan_sub_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    this.perihal = "Permohonan " + response_data_fix.referensi[0].mps_nama;
                    this.perizinan_sub_form = response_data_fix.referensi[0];
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
            });

        // get disclaimer konfig
        var config_data_disclaimer = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/get-disclaimer",
            params: {
                mps_id: self.perizinan_sub_id,
            },
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_data_disclaimer)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data.konfig;
                if (response_data.meta.code == 200) {
                    let clear_data = [];
                    $.each(response_data_fix, function (indexInArray, valueOfElement) {
                        clear_data.push({
                            disclaimer: valueOfElement.disclaimer,
                            urutan: valueOfElement.mdk_urutan,
                        });
                    });
                    self.loadingTable = false;
                    self.data_disclaimer = clear_data;
                    console.log(self.data_disclaimer);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    methods: {
        // Wizard
        changeWizard(target, aksi, id_trigger) {
            this.content_before_loading_trig = $("#" + id_trigger).html();
            $("#" + id_trigger).html(`<i class="fa fa-spin fa-refresh"></i> Proses...`);
            if (this.wizard_now == "data-umum") {
                this.storeDataUmum(target, aksi, id_trigger);
            } else if (this.wizard_now == "data-teknis") {
                this.storeDataTeknis(target, aksi, id_trigger);
            } else if (this.wizard_now == "dokumen-persyaratan") {
                this.storeDokumen(target, aksi, id_trigger);
            } else {
                this.processChangeWizard(target, aksi, id_trigger);
            }
            // this.processChangeWizard(target, aksi, id_trigger);
        },
        // Function Store
        storeDataUmum(target, aksi, id_trigger) {
            let self = this;

            let required_form = new Promise(function (resolve, reject) {
                // cek required
                if (self.nomor_surat == "" || self.nomor_surat == null || self.tgl_surat == "" || self.tgl_surat == null || self.perihal == "" || self.perihal == null) {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan Data Umum Terisi Untuk Melanjutkan",
                    });
                    reject("stop");
                }
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }
                axios
                    .post(
                        process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/store-data-umum",
                        {
                            nib: self.session.nib,
                            nomor_surat: self.nomor_surat,
                            tgl_surat: self.tgl_surat,
                            perihal: self.perihal,
                            perizinan_id: self.perizinan_id,
                            perizinan_sub_id: self.perizinan_sub_id,
                            save_as: self.save_as,
                            permohonan_id: self.permohonan_id,
                            kode_izin: self.kode_izin,
                            id_izin: self.id_izin,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        var permohonan = response.data;
                        self.permohonan_id = permohonan.data.p_id;
                        // Untuk set permohonan id pada form hasil generate
                        // setTimeout(function() { $('#permohonan_id').val(self.permohonan_id); }, 1000);
                        self.processChangeWizard(target, aksi, id_trigger);
                    })
                    .catch((e) => {
                        self.axiosCatchError = e.response;
                        console.log(e);
                    });
            });
        },
        storeDataTeknis(target, aksi, id_trigger) {
            let self = this;
            var form = $("#from-teknis-generate");
            var mydata = new FormData(form[0]);
            mydata.append("permohonan_id", self.permohonan_id);
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/store-data-teknis", mydata, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_data = response.data.data;
                    self.kelengkapan_data_teknis = res_data.is_lengkap;
                    self.message_kelengkapan_data_teknis = res_data.message;
                    self.processChangeWizard(target, aksi, id_trigger);
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
        inputFileDocument(keys) {
            let self = this;
            if ($("#files-doc-" + keys)[0].files[0]) {
                if ($("#files-doc-" + keys)[0].files[0].size < 2242880) {
                    $("#duloading-" + keys).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var konfig_dokumen_in = this.list_document[keys];
                    var permohonan_dokumen_in = this.permohonan_dokumen[keys];
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-" + keys)[0].files[0]);
                    data.append("permohonan_id", this.permohonan_id);
                    data.append("dokumen_persyaratan_id", konfig_dokumen_in.mdp_id);
                    data.append("permohonan_dokumen", JSON.stringify(permohonan_dokumen_in));
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            console.log(response.data.data.permohonan_dokumen);
                            permohonan_dokumen_in = response.data.data.permohonan_dokumen;
                            permohonan_dokumen_in.file = response.data.data.file;
                            urlres += response.data.data.file;
                            $("#duloading-" + keys).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>');
                            self.permohonan_dokumen[keys] = permohonan_dokumen_in;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-" + keys).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + " </span>");
                            }
                            console.log(e.response);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        storeDokumen(target, aksi, id_trigger) {
            let self = this;
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/store-dokumen-persyaratan",
                    {
                        dokumen: JSON.stringify(self.permohonan_dokumen),
                        permohonan_id: self.permohonan_id,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_data = response.data.data;
                    self.kelengkapan_dokumen_persyaratan = res_data.is_lengkap;
                    self.message_kelengkapan_dokumen_persyaratan = res_data.message;
                    self.processChangeWizard(target, aksi, id_trigger);
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
        processChangeWizard(target, aksi, id_trigger) {
            $("#" + id_trigger).html(this.content_before_loading_trig);

            $("#" + target + "-parent").addClass("current");
            $("#" + this.wizard_now).removeClass("show active");
            $("#" + target).addClass("show active");
            if (aksi == "next") {
                this.width_garis += 33;
                $("#line-wizard").css("width", this.width_garis + "%");
            } else {
                this.width_garis -= 33;
                $("#line-wizard").css("width", this.width_garis + "%");
            }
            if (target == "preview") {
                this.loadPreview();
            }
            this.wizard_now = target;
        },
        loadPreview() {
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/preview", {
                    params: {
                        permohonan_id: this.permohonan_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    this.page_preview = response.data;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    console.log(this.e);
                });
        },
        checkAjukan() {
            let self = this;
            var li_required = ``;
            if (self.kelengkapan_data_teknis == false) {
                $.each(self.message_kelengkapan_data_teknis, function (index, val) {
                    li_required += `<li>` + val + `</li>`;
                });
            }
            if (self.kelengkapan_dokumen_persyaratan == false) {
                $.each(self.message_kelengkapan_dokumen_persyaratan, function (index, val) {
                    li_required += `<li>` + val + `</li>`;
                });
            }
            if (self.kelengkapan_data_teknis == false || self.kelengkapan_dokumen_persyaratan == false) {
                var ul_tittle = `<ul class="text-start">` + li_required + `</ul>`;
                Swal.fire({
                    icon: "error",
                    title: "Form Permohonan Belum Lengkap",
                    html: ul_tittle,
                });
            } else {
                this.ajukanPerizinan("ajukan");
            }
        },
        ajukanPerizinan(save_as) {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/submit",
                    {
                        save_as: save_as,
                        permohonan_id: self.permohonan_id,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman dashboard segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.$router.push({ name: "pemohon-dashboard" });
                        }
                    });
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
    },
};
</script>
<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box text-center">
                    <h3 class="mb-1">{{ title }}</h3>
                    <h4 class="my-0" v-if="perizinan_sub_form?.perizinan?.mp_nama">{{ perizinan_sub_form.perizinan.mp_nama }} - {{ perizinan_sub_form.mps_nama }}</h4>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header top-radius-15 bg-success">
                        <h4 class="my-0 text-center text-white"><b>Formulir Permohonan</b></h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 steps">
                                <ul role="tablist">
                                    <li role="tab" class="first current" aria-disabled="false" aria-selected="true" id="data-umum-parent">
                                        <a id="data-umum-tab" data-toggle="tab" data-target="#data-umum" type="button" role="tab" aria-controls="data-umum" aria-selected="true">
                                            <span class="current-info audible"> </span>
                                            <div class="title">
                                                <span class="title-text"> Data Umum </span>
                                                <span class="title-number">01</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done" aria-disabled="false" aria-selected="false" id="data-teknis-parent">
                                        <a id="data-teknis-tab" data-toggle="tab" data-target="#data-teknis" type="button" role="tab" aria-controls="data-teknis" aria-selected="false">
                                            <div class="title">
                                                <span class="title-text"> Data Teknis </span>
                                                <span class="title-number">02</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done" aria-disabled="false" aria-selected="false" id="dokumen-persyaratan-parent">
                                        <a id="dokumen-persyaratan-tab" data-toggle="tab" data-target="#dokumen-persyaratan" type="button" role="tab" aria-controls="dokumen-persyaratan" aria-selected="false">
                                            <div class="title">
                                                <span class="title-text"> Dokumen Persyaratan </span>
                                                <span class="title-number">03</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="last done" aria-disabled="false" aria-selected="false" id="preview-parent">
                                        <a id="preview-tab" data-toggle="tab" data-target="#preview" type="button" role="tab" aria-controls="preview" aria-selected="false">
                                            <div class="title">
                                                <span class="title-text"> Preview </span>
                                                <span class="title-number">04</span>
                                            </div>
                                        </a>
                                    </li>
                                    <div class="garis" id="line-wizard"></div>
                                </ul>
                            </div>
                            <div class="col-md-12">
                                <div class="tab-content mt-3" id="myTabContent">
                                    <div class="tab-pane fade tab-pengajuan show active" id="data-umum" role="tabpanel" aria-labelledby="data-umum-tab">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <table class="table table-sm table-borderless">
                                                            <tbody>
                                                                <tr v-if="id_izin">
                                                                    <th style="width: 250px">Id Izin <span class="text-danger">*</span></th>
                                                                    <td>
                                                                        <input class="form-control" v-model="id_izin" placeholder="Id Izin" readonly />
                                                                    </td>
                                                                </tr>
                                                                <tr v-if="kode_izin">
                                                                    <th style="width: 250px">Kode Izin <span class="text-danger">*</span></th>
                                                                    <td>
                                                                        <input class="form-control" v-model="kode_izin" placeholder="Kode Izin" readonly />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th style="width: 250px">Nomor Surat Permohonan <span class="text-danger">*</span></th>
                                                                    <td>
                                                                        <input class="form-control" v-model="nomor_surat" placeholder="Nomor Surat Permohonan" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th style="width: 250px">Tanggal Surat Permohonan <span class="text-danger">*</span></th>
                                                                    <td>
                                                                        <input type="date" class="form-control" v-model="tgl_surat" placeholder="dd-mm-yyyy" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th style="width: 250px">Perihal</th>
                                                                    <td>
                                                                        <textarea class="form-control" v-model="perihal" placeholder="Perihal" readonly=""></textarea>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="float-end">
                                                    <div class="btn btn-success btn-sm btn-send" v-on:click="changeWizard('data-teknis', 'next', 'trig-next-data-umum')" id="trig-next-data-umum">Lanjutkan <i class="fa fa-arrow-right"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade tab-pengajuan" id="data-teknis" role="tabpanel" aria-labelledby="data-teknis-tab">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div v-html="html_form"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <div class="btn btn-secondary btn-sm" v-on:click="changeWizard('data-umum', 'prev', 'trig-prev-data-teknis')" id="trig-prev-data-teknis"><i class="fa fa-arrow-left"></i> Kembali</div>
                                                <div class="float-end">
                                                    <div class="btn btn-success btn-sm btn-send" v-on:click="changeWizard('dokumen-persyaratan', 'next', 'trig-next-data-teknis')" id="trig-next-data-teknis">Lanjutkan <i class="fa fa-arrow-right"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade tab-pengajuan" id="dokumen-persyaratan" role="tabpanel" aria-labelledby="dokumen-persyaratan-tab">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6" v-for="(list_document_val, list_document_key) in list_document" :key="list_document_key">
                                                        <div class="card shadow-lg rounded">
                                                            <div class="card-body">
                                                                <h5 class="text-center">
                                                                    <b>{{ list_document_val.mdp_nama }} </b>
                                                                </h5>
                                                                <div class="row">
                                                                    <div class="col-md-12" v-if="list_document_val.mdp_is_isian_custom == 't'">
                                                                        <label for="">{{ list_document_val.mdp_label_isian_custom }} <span class="text-danger">*</span></label>
                                                                        <input type="text" v-model="permohonan_dokumen[list_document_key].pd_mdp_isian_custom" class="form-control" />
                                                                    </div>
                                                                    <div class="col-md-6" v-if="list_document_val.mdp_is_tanggal_awal == 't'">
                                                                        <label for="">Tanggal Awal <span class="text-danger">*</span></label>
                                                                        <input type="date" class="form-control" v-model="permohonan_dokumen[list_document_key].pd_mdp_tanggal_awal" />
                                                                    </div>
                                                                    <div class="col-md-6" v-if="list_document_val.mdp_is_tanggal_berakhir == 't'">
                                                                        <label for="">Tanggal Akhir <span class="text-danger">*</span></label>
                                                                        <input type="date" class="form-control" v-model="permohonan_dokumen[list_document_key].pd_mdp_tanggal_akhir" />
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        {{ list_document_val }}
                                                                        <label for="">File <span class="text-danger" v-if="list_document_val.mdp_is_mandatori == 't'">*</span></label>
                                                                        <input type="file" class="form-control mb-0" :id="'files-doc-' + list_document_key" v-on:change="inputFileDocument(list_document_key)" accept=".pdf, .word, .excel, .png, .jpg" />
                                                                        <div class="respond-input-file float-left" :id="'duloading-' + list_document_key">
                                                                            <!-- <div target="_blank" class="hover-pointer" ><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></div> -->
                                                                        </div>
                                                                        <br />
                                                                        <small class="float-right">*) Max File 2 MB</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="btn btn-secondary btn-sm" v-on:click="changeWizard('data-teknis', 'prev', 'trig-prev-dokumen-persyaratan')" id="trig-prev-dokumen-persyaratan"><i class="fa fa-arrow-left"></i> Kembali</div>
                                                <div class="float-end">
                                                    <div class="btn btn-success btn-sm btn-send" v-on:click="changeWizard('preview', 'next', 'trig-next-dokumen-persyaratan')" id="trig-next-dokumen-persyaratan">Lanjutkan <i class="fa fa-arrow-right"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade tab-pengajuan" id="preview" role="tabpanel" aria-labelledby="preview-tab">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div v-html="page_preview"></div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <b>Disclaimer</b>
                                                        <table class="table mb-0 table-condensed">
                                                            <tr v-for="(row_data_disclaimer, key_disclaimer) in data_disclaimer" :key="key_disclaimer">
                                                                <td class="text-center" style="width: 5%">{{ row_data_disclaimer.urutan }}.</td>
                                                                <td>
                                                                    {{ row_data_disclaimer.disclaimer.md_nama }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <hr />
                                                        <div class="form-group form-check">
                                                            <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="setuju_permohonan" />
                                                            <label class="form-check-label" for="exampleCheck1">Saya menyetujui data permohonan diatas untuk diproses</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="btn btn-secondary btn-sm" v-on:click="changeWizard('dokumen-persyaratan', 'prev', 'trig-prev-preview')" id="trig-prev-preview"><i class="fa fa-arrow-left"></i> Kembali</div>
                                                <div class="float-end">
                                                    <div class="btn btn-info btn-sm btn-draft" v-on:click="ajukanPerizinan('draft')"><i class="fa fa-save"></i> Draft</div>
                                                    <div class="btn btn-success btn-sm btn-ajukan" v-on:click="checkAjukan()" v-if="setuju_permohonan"><i class="fa fa-paper-plane"></i> Ajukan</div>
                                                    <div class="btn btn-secondary btn-sm btn-ajukan disabled" v-else disabled><i class="fa fa-paper-plane"></i> Ajukan</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
